
import React from 'react';

import { Redirect } from 'react-router-dom'
import {LocalStorageService} from './../services/localstorage.service'
import isAuthenticated from '../isAuthenticated'

const BACKEND_DOMAIN = document.location.hostname === 'localhost' ? 'http://localhost:5000' : ''

class LoginForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loggedIn: isAuthenticated(),
            errorOccurred: false
        }
    }

    submit(e) {
        e.preventDefault()
        e.stopPropagation()

        let form = e.target
        let formData = new FormData(form)
        let params = new URLSearchParams(formData)

        // Send request to the server
        fetch(BACKEND_DOMAIN + '/api/login', {
            method: 'POST',
            body: params
        }).then( (res) => {
            return res.json()
        }).then(data => {
            localStorage.setItem('token', data.token)
            LocalStorageService.setAccesses(data.accesses)

            this.setState({loggedIn: true})
        }).catch((err) => {
            e.target.reset()
            this.setState({
                errorOccurred: true
            })

            console.error(err)
        })
    }

    render () {
        if ( this.state.loggedIn ) {
            return (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: this.props.location }
                    }}
                />
            )
        } else {
            return (
                <form onSubmit={this.submit.bind(this)}>
                    {
                        this.state.errorOccurred && <div style={{textAlign: "center"}}>
                            <span style={{fontSize: 25, backgroundColor: '#cc0000', color: '#ffccff'}}>Failed to login, please, retry</span>
                        </div>
                    }
                    <table cellSpacing="20">
                        <tbody>
                        <tr>
                            <td colSpan="2">
                                Please authorize:
                            </td>
                        </tr>
                        <tr>
                            <td align="left">
                                Username:
                            </td>
                            <td>
                                <input type="text" name="username" pattern=".{2,16}" required/>
                            </td>
                        </tr>
                        <tr>
                            <td align="left">
                                Password:
                            </td>
                            <td>
                                <input type="password" name="password" pattern=".{3,20}" required/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <input type="submit" value="Log in"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            )
        }
    }
}

export default LoginForm
