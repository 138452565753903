import {Autocomplete, styled} from "@mui/material";

export const NotsyAutocomlete = styled(Autocomplete)({
    'label': {
        color: '#FFFFFF'
    },
    'input': {
        color: '#FFFFFF'
    },
    'svg': {
        color: '#FFFFFF',
        '&:hover': {
            color: '#197cd2'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#197cd2',
        },
        '&:hover fieldset': {
            borderColor: '#197cd2',
        },

    }
})