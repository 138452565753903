
import './App.css';
import PrivateRoute from "./PrivateRoute";
import StartAb from "./components/StartAb";
import {SwitchToMasterRoute, UpdateSettingsRoute, updateVariationBranchRoute} from "./components/DeployInterface";
import Demos from "./components/ListDemos";
import DeploysHistory from "./components/DeploysHistory";
import AbHistory from "./components/AbHistory";
import DeployVideo from "./components/DeployVideo";
import UpdateAbVariations from "./components/UpdateAbVariations";
import RolloutVariation from "./components/RolloutVariation";
import ExperimentDescriptionTemplateGenerator from "./components/experimentDescriptionTemplateGenerator";
import DeployNewSite from "./components/DeployNewSite";
import ListSites from "./components/ListSites";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from "react-router-dom";

import React from 'react';

import LoginForm from './components/LoginForm'
import {RollbackPage} from "./components/Rollback.page";


function App() {
    function NoMatch() {
        let location = useLocation();

        return (
            <div>
                <h3>
                    No match for <code>{location.pathname}</code>
                </h3>
            </div>
        );
    }
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <Switch>
                        <Route path="/login">
                            <LoginForm />
                        </Route>
                        <PrivateRoute path="/startAb/:siteId" comp={StartAb} />
                        <PrivateRoute path="/updateLogicAndSettingsToStaging/:siteId/:logic/:settingsBranch" comp={updateVariationBranchRoute} />
                        <PrivateRoute path="/Demos/:siteId" comp={Demos} />
                        <PrivateRoute path="/history/:siteId/:variation?" comp={DeploysHistory} />
                        <PrivateRoute path="/abHistory" useStub={true} comp={AbHistory} />
                        <PrivateRoute path="/video" comp={DeployVideo} />
                        <PrivateRoute path="/updateSettings/:siteId/:variation?" comp={UpdateSettingsRoute} />
                        <PrivateRoute path="/switchToMaster/:siteId/:variation?" comp={SwitchToMasterRoute} />
                        <PrivateRoute path="/updateAb/:siteId/:experimentId" comp={UpdateAbVariations} />
                        <PrivateRoute path="/rolloutVariation/:siteId/:variation?" comp={RolloutVariation} />
                        <PrivateRoute path="/experimentDescriptionGenerator/:siteId/:experimentId/:variations?" comp={ExperimentDescriptionTemplateGenerator} />
                        <PrivateRoute path="/rollback/:siteId/:buildPath" comp={RollbackPage}></PrivateRoute>
                        <PrivateRoute path="/newSite" comp={DeployNewSite} />
                        <PrivateRoute exact path="/" comp={ListSites} />
                        <Route path="*">
                            <NoMatch />
                        </Route>
                    </Switch>
                </header>

            </div>
        </Router>
    );
}


export function loader() {
    return (
         <div style={{position: "absolute", width: "100vw", height: "100vh", display: 'flex',justifyContent: "center", alignItems: "center"}}>
            <h1 style={{color: "white"}}>
                Loading..
            </h1>
         </div>
    );
}

export default App;
