import {queryApiPost} from "../api";

class DeployEventsTrackerService {
    deploySessionId;

    deployEventsNames =  {
        abSessionStarted: 'ab_session_started',
        abSessionFinished: 'ab_session_finished',

        settingsBranchLoadingStarted: 'settings_branch_loading_started',
        settingsBranchLoadingFinished: 'settings_branch_loading_finished',
        settingsBranchLoadingFailed: 'settings_branch_loading_failed',

        higherTagsLoadingStarted: 'higher_tags_loading_started',
        higherTagsLoadingFinished: 'higher_tags_loading_finished',
        higherTagsLoadingFailed: 'higher_tags_loading_failed',

        commitsFetchStarted: 'commits_fetch_started',
        commitsFetchFinished: 'commits_fetch_finished',
        commitsFetchFailed: 'commits_fetch_failed',

        deployToStagingStarted: 'deploy_to_staging_started',
        deployToStagingFinished: 'deploy_to_staging_finished',
        deployToStagingFailed: 'deploy_to_staging_failed',

        deployToProdStarted: 'deploy_to_prod_started',
        deployToProdFinished: 'deploy_to_prod_finished',
        deployToProdFailed: 'deploy_to_prod_failed',

        abStarted: 'ab_started',
        abFinished: 'ab_finished',
        abFailed: 'ab_failed',
    }

    trackCommitsFetchStarted() {
        this.postEvent([this.deployEventsNames.commitsFetchStarted])
    }

    trackCommitsFetchFinished() {
        this.postEvent([this.deployEventsNames.commitsFetchFinished])
    }

    trackCommitsFetchFailed(error) {
        this.postEvent([this.deployEventsNames.commitsFetchFailed], error)
    }

    trackDeployToStagingStarted() {
        this.postEvent([this.deployEventsNames.deployToStagingStarted])
    }

    trackDeployToStagingFinished() {
        this.postEvent([this.deployEventsNames.deployToStagingFinished])
    }

    trackDeployToStagingFailed(error) {
        this.postEvent([this.deployEventsNames.deployToStagingFailed], error)
    }

    trackDeployToProdStarted() {
        this.postEvent([this.deployEventsNames.deployToProdStarted])
    }

    trackDeployToProdFinished() {
        this.postEvent([this.deployEventsNames.deployToProdFinished])

    }

    trackDeployToProdFailed(error) {
        this.postEvent([this.deployEventsNames.deployToProdFailed], error)
    }

    trackAbStarted() {
        this.postEvent([this.deployEventsNames.abStarted])
    }
    trackABbFinished() {
        this.postEvent([this.deployEventsNames.abFinished, this.deployEventsNames.abSessionFinished])
    }
    trackAbFailed(error) {
        this.postEvent([this.deployEventsNames.abFailed], error)
    }

    trackSettingsBranchLoadingStarted() {
        this.setDeploySessionId()

        this.postEvent([this.deployEventsNames.abSessionStarted, this.deployEventsNames.settingsBranchLoadingStarted])
    }

    trackSettingsBranchLoadingFinished() {
        this.postEvent([this.deployEventsNames.settingsBranchLoadingFinished])
    }

    trackSettingsBranchLoadingFailed(error) {
        this.postEvent([this.deployEventsNames.settingsBranchLoadingFailed], error)
    }


    trackHigherVersionTagsLoadingStarted() {
        this.postEvent([this.deployEventsNames.higherTagsLoadingStarted])
    }

    trackHigherVersionTagsLoadingFinished() {
        this.postEvent([this.deployEventsNames.higherTagsLoadingFinished])
    }

    trackHigherVersionTagsLoadingFailed(error) {
        this.postEvent([this.deployEventsNames.higherTagsLoadingFailed], error)
    }

    postEvent(deployEventNames, error) {
        if (!this.deploySessionId) throw {errorMessage: 'No deploy session id'}

        queryApiPost(`/api/trackDeployEvent`,
            {deployEventNames, deploySessionId: this.deploySessionId, timestamp: Date.now(), error}
        )
    }

    setDeploySessionId() {
        this.deploySessionId = String(Math.random() * Date.now())
    }
}

const deployEventsTrackerService = new DeployEventsTrackerService();
export default deployEventsTrackerService