
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import isAuthenticated from './isAuthenticated'

// Router that checks if user is logged-in
// If not, redirect to "/login"
const PrivateRoute = ({ comp: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...rest} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
)

export default PrivateRoute
