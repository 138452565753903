
import React from 'react';

import {queryApiGet, queryApiPost} from '../api.js'
import ErrorComponent from "./error";

class DeployNewSite extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            deployCompleted: false,
            errorOccurred: false,
            loadingTags: true,
            logicVersions: null,
            loadingNotDeployedSites: true,
            notDeployedSites: null,
            deployInProcess: false
        }

        this.handleDeployFormSubmit = this.handleDeployFormSubmit.bind(this)
    }

    componentDidMount() {
        this.loadTags()
        this.loadNotDeployedSites()
    }

    loadTags() {
        this.setState({
            loadingTags: true
        })

        queryApiGet('/api/tagsNames').then((tags) => {
            this.setState({
                loadingTags: false,
                logicVersions: tags.slice(0, 50)
            })
        }).catch(er => this.setErrorState(er))
    }

    setErrorState(errorMessage) {
        const res = [...this.state.errorMessages || [], errorMessage]

        this.setState({errorMessages: res})
    }

    loadNotDeployedSites() {
        this.setState({
            loadingNotDeployedSites: true
        })

        queryApiGet('/api/getNewSites').then((sites) => {
            this.setState({
                loadingNotDeployedSites: false,
                notDeployedSites: sites
            })
        }).catch(er => this.setErrorState(er))
    }

    handleDeployFormSubmit(e) {
        e.preventDefault()

        const controls = Array.from(e.target.elements)
        const siteId = controls.find(el => el.id === 'select_website').value
        const logicVersion = controls.find(el => el.id === 'select_logic_version').value

        this.setState({deployInProcess: true})

        queryApiPost('/api/deployNewSite', {siteId, logicVersion}).then(() => {
            this.setState({ deployInProcess: false, deployCompleted: true})
        }).catch(er => this.setErrorState(er))
    }

    render () {
        if (this.state.errorMessages?.length) {
            return (<ErrorComponent errorMessages={this.state.errorMessages}></ErrorComponent>)
        }

        if (this.state.deployCompleted) {
            return (
                <div style={{fontSize: 25, backgroundColor: '#009933', color: 'Yellow', marginTop: '10px'}}>Done</div>
            )
        }

        if (this.state.loadingTags || this.state.loadingNotDeployedSites || this.state.deployInProcess) {
            return (
                <div style={{textAlign: "center"}}>
                    Loading. Please wait...
                </div>
            )
        }

        if (!this.state.notDeployedSites.length) {
            return <span style={{fontSize: 25, backgroundColor: '#cc0000', color: '#ffccff'}}>No new sites to deploy</span>
        }

        const logicVersionsOptions = this.state.logicVersions.map(
            logicVersion => <option key={logicVersion}>{logicVersion}</option>
        )

        const websitesOptions = this.state.notDeployedSites.map(
            site => <option key={site}>{site}</option>
        )

        return (
            <form onSubmit={this.handleDeployFormSubmit}>
                <table cellPadding={20}>
                    <tbody>
                    <tr>
                        <td>
                            <label htmlFor={'select_website'}>Choose website</label>
                        </td>
                        <td width={'200px'}>
                            <select id={'select_website'} name="siteId" style={{width: '70%', fontSize: 20}} required>
                                {websitesOptions}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label htmlFor={'select_logic_version'}>Choose logic version</label>
                        </td>
                        <td>
                            <select id={'select_logic_version'} name="logicVersion" style={{width: '70%', fontSize: 20}} required>
                                {logicVersionsOptions}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button type={'submit'} style={{width: '250px', height: '60px', fontSize: 25, margin: '5px'}}>Deploy</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
        )
    }
}

export default DeployNewSite
