
import React from 'react';

import abData from "../ab-data";
import {queryApiGet} from "../api";
import ErrorComponent from "./error";

class AbHistory extends React.Component {
    constructor(props) {
        super(props);

        if (!props.useStub) {
            this.state = {
                loadingData: true,
                historyData: null
            }
        } else {



            this.state = {
                loadingData: false,
                historyData: this.addPrevBuilds(abData)
            }
        }
    }

    addPrevBuilds(historyData) {
        const prevBuildsByVariation = new Map()

        // map in reverse order
        historyData.slice(0).reverse().forEach(ab => {
            if (ab.variationBuilds) {
                ab.variationBuilds.slice(0).reverse().forEach(build => {
                    build.prevBuild = prevBuildsByVariation.get(build.variation)
                    prevBuildsByVariation.set(build.variation, build)
                })
            }

            return ab
        })

        console.log(historyData)

        return historyData
    }

    componentDidMount() {
        if (!this.props.useStub) {
            this.loadHistoryData()
        }
    }

    loadHistoryData() {
        queryApiGet('/api/abHistory').then(
            (data) => {
                this.setState({
                    loadingData: false,
                    historyData: data
                })
            }
        ).catch(er => this.setErrorState(er))
    }

    setErrorState(errorMessage) {
        const res = [...this.state.errorMessages || [], errorMessage]

        this.setState({errorMessages: res})
    }


    render () {
        if (this.state.errorMessages?.length) {
            return (<ErrorComponent errorMessages={this.state.errorMessages}></ErrorComponent>)
        }

        if (this.state.loadingData) {
            return (
                <div style={{textAlign: "center"}}>
                    Loading. Please wait...
                </div>
            )
        }

        const lol = [].concat.apply([], this.state.historyData.map((ab, n) => {
            if (ab.variationBuilds.length === 0) {
                return (
                    [
                        <AbHistoryRow key={n + " " + 0} abBuild={ab} showAbInfo={true}/>
                    ]
                )
            }  else {
                return ab.variationBuilds.map((variationBuild, i) =>
                    (
                        <AbHistoryRow key={n + " " + i} abBuild={ab} showAbInfo={i === 0} variationBuild={variationBuild}/>
                    )
                )
            }

        }))

        return (
            <table border={1} width={"70%"} cellPadding={3} style={{fontSize: 10}}>
                <thead>
                    <tr>
                        <td>AB info</td>
                        <td>A</td>
                        <td>B</td>
                        <td>C</td>
                        <td>D</td>
                        <td>E</td>
                    </tr>
                </thead>
                <tbody>
                    {lol}
                </tbody>
            </table>
        )
    }
}

class AbHistoryRow extends React.Component {
    getChangeStr(v1, v2) {
        if (v1.hash === v2.hash) {
            return null
        } else if (v1.commitish === v2.commitish) {
            return v1.commitish.substring(7) + ': (' + v1.hash.substring(0, 6) + ' -> ' + v2.hash.substring(0, 6) + ')'
        } else {
            return v1.commitish.substring(7) + ':' + v1.hash.substring(0, 6) + ' -> ' + v2.commitish.substring(7) + ':' + v2.hash.substring(0, 6)
        }
    }

    render () {
        const variations = ['A', 'B', 'C', 'D', 'E'].map(variation => {

            let variationBuildInfo

            if (!this.props.variationBuild || this.props.variationBuild.variation !== variation) {
                variationBuildInfo = '\u00A0'
            } else if (this.props.variationBuild.type !== "normal") {
                variationBuildInfo = (
                    <div key={variation}>
                        {this.props.variationBuild.type}
                    </div>
                )
            } else {
                console.log(this.props.variationBuild, this.props.variationBuild.prevBuild)

                if (!this.props.variationBuild.prevBuild) {
                    variationBuildInfo = (
                        <div key={variation}>
                            {this.props.variationBuild.version.logic.commitish.substring(7)}<br />
                            {this.props.variationBuild.version.settings.commitish.substring(7)}
                        </div>
                    )
                } else {
                    if (this.props.variationBuild.prevBuild.type !== "normal") {
                        variationBuildInfo = (
                            <div key={variation}>
                                {this.props.variationBuild.version.logic.commitish.substring(7)}<br />
                                {this.props.variationBuild.version.settings.commitish.substring(7)}
                            </div>
                        )
                    } else {
                        const logicChange = this.getChangeStr(
                            this.props.variationBuild.prevBuild.version.logic,
                            this.props.variationBuild.version.logic
                        )
                        const settingsChange = this.getChangeStr(
                            this.props.variationBuild.prevBuild.version.settings,
                            this.props.variationBuild.version.settings
                        )

                        variationBuildInfo = (
                            <div key={variation}>
                                {logicChange ? 'Logic:' : ''}
                                {logicChange}
                                {logicChange && settingsChange &&
                                    <br />
                                }
                                {settingsChange ? 'Settings::' : ''}
                                {settingsChange}
                            </div>
                        )
                    }
                }


            }

            let color

            if (this.props.abBuild.ab instanceof Array) {
                if (variation in this.props.abBuild.ab[0].variations && variation in this.props.abBuild.ab[1].variations) {
                    color = '#6e6c0b'
                } else if (variation in this.props.abBuild.ab[0].variations) {
                    color = '#591d1a'
                } else if (variation in this.props.abBuild.ab[1].variations) {
                    color = '#1a592b'
                } else {
                    color = 'black'
                }
            } else {
                color = variation in this.props.abBuild.ab.variations ? 'gray': 'black'
            }

            return (
                <td key={variation} style={{backgroundColor: color}}>
                    {variationBuildInfo}
                </td>
            )
        })

        const abBuildInfo = this.props.showAbInfo ? <AbInfoCell abBuild={this.props.abBuild}/> : null

        return (
            <tr>
                {abBuildInfo}
                {variations}
            </tr>
        )
    }
}

class AbInfoCell extends React.Component {
    abInfoToShortDescription(ab) {
        let parallelAbs

        if (!(ab instanceof Array)) {
            parallelAbs = [ab]
        } else {
            parallelAbs = ab
        }

        const joinWithElement = <span style={{color: 'cyan'}}> || </span>

        return parallelAbs.map((abInfo, i) => {
            const splitInfo = typeof abInfo.split === "object" ? <span style={{fontWeight: 'bold', color: '#ffff99'}}>
                {'[' + abInfo.split.map(p => Math.round(p *100)) + ']'}
            </span> : ''

            let backgroundColor

            if (parallelAbs.length === 1) {
                backgroundColor = ''
            } else {
                backgroundColor = i === 0 ? '#591d1a': '#1a592b'
            }

            return (
                <span key={i} style={{backgroundColor: backgroundColor}}>
                    {abInfo.id + ' ' + Object.keys(abInfo.variations).join('/')}
                    {' '}
                    {splitInfo}
                    {i < parallelAbs.length - 1 ? joinWithElement : ''}
                </span>
            )
        })
    }

    render() {
        //console.log(this.props.ab)

        if (!this.props.abBuild) {
            return ''
        } else {
            return <td rowSpan={this.props.abBuild.variationBuilds.length > 0 ? this.props.abBuild.variationBuilds.length : 1}>
                {this.abInfoToShortDescription(this.props.abBuild.ab)}
            </td>
        }
    }
}

export default AbHistory
