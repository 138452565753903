
const abData = [
    {
        "url": "ym.js",
        "type": "ab",
        "lastModified": "Wed, 28 Jul 2021 13:18:22 GMT",
        "ab": {
            "id": "hide_float320_on_video_stick",
            "siteId": "obz",
            "buildName": "ym",
            "split": [
                0.5,
                0.25,
                0.25
            ],
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                },
                "C": {
                    "id": "C",
                    "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                }
            }
        },
        "dt": "2021-07-28T13:18:22.000Z",
        "variationBuilds": []
    },
    {
        "url": "ym_backup_2021_07_28_15_18_21.js",
        "type": "ab",
        "lastModified": "Wed, 28 Jul 2021 13:18:21 GMT",
        "ab": {
            "id": "mobile_instream_2atempts_50_25_25",
            "siteId": "obz",
            "buildName": "ym",
            "split": [
                0.5,
                0.25,
                0.25
            ],
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                },
                "C": {
                    "id": "C",
                    "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                }
            }
        },
        "dt": "2021-07-28T13:18:21.000Z",
        "variationBuilds": []
    },
    {
        "url": "ym_backup_2021_07_15_13_38_06.js",
        "type": "ab",
        "lastModified": "Thu, 15 Jul 2021 11:38:06 GMT",
        "ab": {
            "id": "mobile_instream_3",
            "siteId": "obz",
            "buildName": "ym",
            "split": [
                0.5,
                0.25,
                0.25
            ],
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                },
                "C": {
                    "id": "C",
                    "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                }
            }
        },
        "dt": "2021-07-15T11:38:06.000Z",
        "variationBuilds": [
            {
                "url": "ym.js",
                "type": "normal",
                "lastModified": "Wed, 28 Jul 2021 13:17:04 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "01093f94d5b9a943919be07eebc741adbab86139"
                    },
                    "settings": {
                        "commitish": "origin/obz_video_hide_float320_on_video_stick",
                        "hash": "6476adbac9464429ea1ea125cd18ff92a943f2c9"
                    }
                },
                "variation": "B",
                "dt": "2021-07-28T13:17:04.000Z"
            },
            {
                "url": "ym_backup_2021_07_28_15_17_03.js",
                "type": "normal",
                "lastModified": "Wed, 28 Jul 2021 13:17:03 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "19daf721f096ea2a3d3fce01d199c7b16638a6aa"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile",
                        "hash": "1bb3523d8aaeb628983db8452683cc064b9f0f7b"
                    }
                },
                "variation": "B",
                "dt": "2021-07-28T13:17:03.000Z"
            },
            {
                "url": "ym.js",
                "type": "normal",
                "lastModified": "Thu, 22 Jul 2021 15:44:07 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "19daf721f096ea2a3d3fce01d199c7b16638a6aa"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "6dba660dca76a4fe2d641b754c0e1e4f4c9e14ac"
                    }
                },
                "variation": "A",
                "dt": "2021-07-22T15:44:07.000Z"
            },
            {
                "url": "ym_backup_2021_07_22_15_44_05.js",
                "type": "normal",
                "lastModified": "Thu, 22 Jul 2021 15:44:05 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "19daf721f096ea2a3d3fce01d199c7b16638a6aa"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "0ac587daea6e8866f738930f81fe78b7fe755423"
                    }
                },
                "variation": "A",
                "dt": "2021-07-22T15:44:05.000Z"
            },
            {
                "url": "ym.js",
                "type": "normal",
                "lastModified": "Tue, 20 Jul 2021 17:05:38 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "19daf721f096ea2a3d3fce01d199c7b16638a6aa"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "627ea5088a57ef43aa904b2a7b84108fa8909822"
                    }
                },
                "variation": "C",
                "dt": "2021-07-20T17:05:38.000Z"
            },
            {
                "url": "ym_backup_2021_07_20_19_05_38.js",
                "type": "normal",
                "lastModified": "Tue, 20 Jul 2021 17:05:38 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "19daf721f096ea2a3d3fce01d199c7b16638a6aa"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "66a119cfdc4f715b845d4881cf2d3c6fba12a29b"
                    }
                },
                "variation": "C",
                "dt": "2021-07-20T17:05:38.000Z"
            },
            {
                "url": "ym_backup_2021_07_20_18_58_37.js",
                "type": "normal",
                "lastModified": "Tue, 20 Jul 2021 16:58:37 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "19daf721f096ea2a3d3fce01d199c7b16638a6aa"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile",
                        "hash": "dd875fcdab5c23df74ae71560273fb1c6aafaf03"
                    }
                },
                "variation": "B",
                "dt": "2021-07-20T16:58:37.000Z"
            },
            {
                "url": "ym_backup_2021_07_20_18_44_03.js",
                "type": "normal",
                "lastModified": "Tue, 20 Jul 2021 16:44:04 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "19daf721f096ea2a3d3fce01d199c7b16638a6aa"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "681d3bebd4f79097542a3cb1d98252008259a83a"
                    }
                },
                "variation": "A",
                "dt": "2021-07-20T16:44:04.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_07_14_20_44_59.js",
        "type": "ab",
        "lastModified": "Wed, 14 Jul 2021 18:44:59 GMT",
        "ab": {
            "id": "mobile_instream_3",
            "siteId": "obz",
            "buildName": "ym",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                },
                "C": {
                    "id": "C",
                    "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                }
            }
        },
        "dt": "2021-07-14T18:44:59.000Z",
        "variationBuilds": []
    },
    {
        "url": "ym_backup_2021_07_06_13_16_23.js",
        "type": "ab",
        "lastModified": "Tue, 06 Jul 2021 11:16:25 GMT",
        "ab": {
            "id": "mobile_instream_3",
            "siteId": "obz",
            "buildName": "ym",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                },
                "C": {
                    "id": "C",
                    "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                }
            }
        },
        "dt": "2021-07-06T11:16:25.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_07_14_15_38_49.js",
                "type": "normal",
                "lastModified": "Wed, 14 Jul 2021 13:38:49 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "2711b09cfe11da7affb137c9077473a1e53de211"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "a3df63771d43b53ac62ac95d2184d121c9b5b412"
                    }
                },
                "variation": "C",
                "dt": "2021-07-14T13:38:49.000Z"
            },
            {
                "url": "ym_backup_2021_07_14_15_25_10.js",
                "type": "normal",
                "lastModified": "Wed, 14 Jul 2021 13:25:10 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "2711b09cfe11da7affb137c9077473a1e53de211"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile",
                        "hash": "202b76674f3980caf7bb1f5bb37aa22964de53b4"
                    }
                },
                "variation": "B",
                "dt": "2021-07-14T13:25:10.000Z"
            },
            {
                "url": "ym_backup_2021_07_14_15_13_10.js",
                "type": "normal",
                "lastModified": "Wed, 14 Jul 2021 13:13:10 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "2711b09cfe11da7affb137c9077473a1e53de211"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "cbc7025233d3a22d346fda03d91fdb2b1c97be4d"
                    }
                },
                "variation": "A",
                "dt": "2021-07-14T13:13:10.000Z"
            },
            {
                "url": "ym_backup_2021_07_14_00_16_55.js",
                "type": "normal",
                "lastModified": "Tue, 13 Jul 2021 22:16:55 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "2765b5a4453bae5a95fae3d0b39007ff916b0b40"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "8eea46850f784298b36d6f97b22cb316ebf2a8da"
                    }
                },
                "variation": "C",
                "dt": "2021-07-13T22:16:55.000Z"
            },
            {
                "url": "ym_backup_2021_07_14_00_06_23.js",
                "type": "normal",
                "lastModified": "Tue, 13 Jul 2021 22:06:23 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "2765b5a4453bae5a95fae3d0b39007ff916b0b40"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile",
                        "hash": "8cee9705619117698c6b3883ce6c644ae261d9cb"
                    }
                },
                "variation": "B",
                "dt": "2021-07-13T22:06:23.000Z"
            },
            {
                "url": "ym_backup_2021_07_14_00_00_51.js",
                "type": "normal",
                "lastModified": "Tue, 13 Jul 2021 22:00:52 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "2765b5a4453bae5a95fae3d0b39007ff916b0b40"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "cbc7025233d3a22d346fda03d91fdb2b1c97be4d"
                    }
                },
                "variation": "A",
                "dt": "2021-07-13T22:00:52.000Z"
            },
            {
                "url": "ym_backup_2021_07_13_23_46_29.js",
                "type": "normal",
                "lastModified": "Tue, 13 Jul 2021 21:46:29 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "2765b5a4453bae5a95fae3d0b39007ff916b0b40"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "365eb8b541dc51ce3edca382670bcd3c4beb88b9"
                    }
                },
                "variation": "A",
                "dt": "2021-07-13T21:46:29.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_07_05_18_35_23.js",
        "type": "ab",
        "lastModified": "Mon, 05 Jul 2021 16:35:23 GMT",
        "ab": {
            "id": "mobile_instream_2",
            "siteId": "obz",
            "buildName": "ym",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                },
                "E": {
                    "id": "E",
                    "js": "https://cdn-b.notsy.io/obz/e/ym.js"
                }
            }
        },
        "dt": "2021-07-05T16:35:23.000Z",
        "variationBuilds": []
    },
    {
        "url": "ym_backup_2021_07_02_00_11_49.js",
        "type": "ab",
        "lastModified": "Thu, 01 Jul 2021 22:11:50 GMT",
        "ab": [
            {
                "id": "mobile_instream_2",
                "siteId": "obz",
                "buildName": "ym",
                "split": [
                    0.5,
                    0.25,
                    0.25
                ],
                "variations": {
                    "A": {
                        "id": "A",
                        "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                    },
                    "B": {
                        "id": "B",
                        "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                    },
                    "E": {
                        "id": "E",
                        "js": "https://cdn-b.notsy.io/obz/e/ym.js"
                    }
                }
            },
            {
                "id": "vast_in_gam",
                "siteId": "obz",
                "buildName": "ym",
                "split": "even",
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            }
        ],
        "dt": "2021-07-01T22:11:50.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_07_05_18_34_45.js",
                "type": "normal",
                "lastModified": "Mon, 05 Jul 2021 16:34:46 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "4d5ad64c58c29ef3e5db52ea5ab1c8027ec724e5"
                    }
                },
                "variation": "C",
                "dt": "2021-07-05T16:34:46.000Z"
            },
            {
                "url": "ym.js",
                "type": "normal",
                "lastModified": "Thu, 01 Jul 2021 22:46:50 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "2765b5a4453bae5a95fae3d0b39007ff916b0b40"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "5f5b416fd8b24d32765ebd7712bb9eb79763825c"
                    }
                },
                "variation": "E",
                "dt": "2021-07-01T22:46:50.000Z"
            },
            {
                "url": "ym_backup_2021_07_02_00_46_49.js",
                "type": "normal",
                "lastModified": "Thu, 01 Jul 2021 22:46:49 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "5f5b416fd8b24d32765ebd7712bb9eb79763825c"
                    }
                },
                "variation": "E",
                "dt": "2021-07-01T22:46:49.000Z"
            },
            {
                "url": "ym_backup_2021_07_02_00_42_44.js",
                "type": "normal",
                "lastModified": "Thu, 01 Jul 2021 22:42:45 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile",
                        "hash": "8cee9705619117698c6b3883ce6c644ae261d9cb"
                    }
                },
                "variation": "B",
                "dt": "2021-07-01T22:42:45.000Z"
            },
            {
                "url": "ym_backup_2021_07_02_00_37_30.js",
                "type": "normal",
                "lastModified": "Thu, 01 Jul 2021 22:37:30 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "0be3d4ab180b0f9041d5c479d58238c6f9d61bc4"
                    }
                },
                "variation": "A",
                "dt": "2021-07-01T22:37:30.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_06_23_00_19_36.js",
        "type": "ab",
        "lastModified": "Tue, 22 Jun 2021 22:19:37 GMT",
        "ab": [
            {
                "id": "vast_in_gam",
                "siteId": "obz",
                "buildName": "ym",
                "split": "even",
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            },
            {
                "id": "mobile_instream_2",
                "siteId": "obz",
                "buildName": "ym",
                "split": [
                    0.5,
                    0.25,
                    0.25
                ],
                "variations": {
                    "A": {
                        "id": "A",
                        "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                    },
                    "B": {
                        "id": "B",
                        "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                    },
                    "E": {
                        "id": "E",
                        "js": "https://cdn-b.notsy.io/obz/e/ym.js"
                    }
                }
            }
        ],
        "dt": "2021-06-22T22:19:37.000Z",
        "variationBuilds": [
            {
                "url": "ym.js",
                "type": "normal",
                "lastModified": "Tue, 29 Jun 2021 18:37:54 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "4d5ad64c58c29ef3e5db52ea5ab1c8027ec724e5"
                    }
                },
                "variation": "D",
                "dt": "2021-06-29T18:37:54.000Z"
            },
            {
                "url": "ym_backup_2021_06_29_20_37_52.js",
                "type": "normal",
                "lastModified": "Tue, 29 Jun 2021 18:37:53 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile",
                        "hash": "4719972d7a1d9676c6b22c4ad21c9e537addc94d"
                    }
                },
                "variation": "D",
                "dt": "2021-06-29T18:37:53.000Z"
            },
            {
                "url": "ym_backup_2021_06_29_20_37_39.js",
                "type": "normal",
                "lastModified": "Tue, 29 Jun 2021 18:37:40 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_hb_without_ima",
                        "hash": "928a99e1fd290275171fa6e2e29a33db4f295721"
                    }
                },
                "variation": "C",
                "dt": "2021-06-29T18:37:40.000Z"
            },
            {
                "url": "ym_backup_2021_06_25_19_02_07.js",
                "type": "normal",
                "lastModified": "Fri, 25 Jun 2021 17:02:07 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "74f5d412d6e8a43b00dcff0bb23dfb575dc5ba8c"
                    }
                },
                "variation": "E",
                "dt": "2021-06-25T17:02:07.000Z"
            },
            {
                "url": "ym_backup_2021_06_23_01_23_04.js",
                "type": "normal",
                "lastModified": "Tue, 22 Jun 2021 23:23:05 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "12db77e194fc0c097d6cc17433e31694aeccd517"
                    }
                },
                "variation": "E",
                "dt": "2021-06-22T23:23:05.000Z"
            },
            {
                "url": "ym_backup_2021_06_23_01_08_32.js",
                "type": "normal",
                "lastModified": "Tue, 22 Jun 2021 23:08:32 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile",
                        "hash": "8cee9705619117698c6b3883ce6c644ae261d9cb"
                    }
                },
                "variation": "E",
                "dt": "2021-06-22T23:08:32.000Z"
            },
            {
                "url": "ym_backup_2021_06_23_01_02_17.js",
                "type": "normal",
                "lastModified": "Tue, 22 Jun 2021 23:02:18 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "12db77e194fc0c097d6cc17433e31694aeccd517"
                    }
                },
                "variation": "E",
                "dt": "2021-06-22T23:02:18.000Z"
            },
            {
                "url": "ym_backup_2021_06_23_00_56_03.js",
                "type": "normal",
                "lastModified": "Tue, 22 Jun 2021 22:56:04 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile",
                        "hash": "d317ee125a7826c87609c235409cc42cb36c636d"
                    }
                },
                "variation": "B",
                "dt": "2021-06-22T22:56:04.000Z"
            },
            {
                "url": "ym_backup_2021_06_23_00_20_18.js",
                "type": "normal",
                "lastModified": "Tue, 22 Jun 2021 22:20:19 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "e0d8d3ca1f7dcffba4ea2ff91f91db198528c7c3"
                    }
                },
                "variation": "A",
                "dt": "2021-06-22T22:20:19.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_06_22_00_52_53.js",
        "type": "ab",
        "lastModified": "Mon, 21 Jun 2021 22:52:53 GMT",
        "ab": [
            {
                "id": "vast_in_gam",
                "siteId": "obz",
                "buildName": "ym",
                "split": "even",
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            },
            {
                "id": "mobile_instream",
                "siteId": "obz",
                "buildName": "ym",
                "split": [
                    0.75,
                    0.25
                ],
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            }
        ],
        "dt": "2021-06-21T22:52:53.000Z",
        "variationBuilds": []
    },
    {
        "url": "ym_backup_2021_06_21_09_58_19.js",
        "type": "ab",
        "lastModified": "Mon, 21 Jun 2021 07:58:19 GMT",
        "ab": {
            "id": "ym_1_0_8",
            "siteId": "klo",
            "buildName": "ym",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/klo/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/klo/b/ym.js"
                }
            }
        },
        "dt": "2021-06-21T07:58:19.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_06_22_00_51_33.js",
                "type": "normal",
                "lastModified": "Mon, 21 Jun 2021 22:51:34 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse",
                        "hash": "04981cc86d0cb563a9a3dc94df4e1e466f9977e0"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "c234c5c9507bb5e7494270f47c3b697785a837c1"
                    }
                },
                "variation": "A",
                "dt": "2021-06-21T22:51:34.000Z"
            },
            {
                "url": "ym_backup_2021_06_22_00_41_47.js",
                "type": "normal",
                "lastModified": "Mon, 21 Jun 2021 22:41:47 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video_desktop_and_mobile_3_attempts_limit",
                        "hash": "a66be85f4706e42eda7d13b73cccf4dd6cd89975"
                    }
                },
                "variation": "E",
                "dt": "2021-06-21T22:41:47.000Z"
            },
            {
                "url": "ym_backup_2021_06_22_00_40_59.js",
                "type": "normal",
                "lastModified": "Mon, 21 Jun 2021 22:41:00 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_hb_without_ima",
                        "hash": "928a99e1fd290275171fa6e2e29a33db4f295721"
                    }
                },
                "variation": "B",
                "dt": "2021-06-21T22:41:00.000Z"
            },
            {
                "url": "ym_backup_2021_06_22_00_25_48.js",
                "type": "dev",
                "lastModified": "Mon, 21 Jun 2021 22:25:49 GMT",
                "variation": "E",
                "dt": "2021-06-21T22:25:49.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_06_21_09_56_26.js",
        "type": "ab",
        "lastModified": "Mon, 21 Jun 2021 07:56:26 GMT",
        "ab": [
            {
                "id": "vast_in_gam",
                "siteId": "obz",
                "buildName": "ym",
                "split": "even",
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            },
            {
                "id": "mobile_instream",
                "siteId": "obz",
                "buildName": "ym",
                "split": [
                    0.75,
                    0.25
                ],
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            }
        ],
        "dt": "2021-06-21T07:56:26.000Z",
        "variationBuilds": []
    },
    {
        "url": "ym_backup_2021_06_18_12_47_54.js",
        "type": "ab",
        "lastModified": "Fri, 18 Jun 2021 10:47:55 GMT",
        "ab": [
            {
                "deviceClasses": [
                    "desktop",
                    "ipad"
                ],
                "id": "vast_in_gam",
                "siteId": "obz",
                "buildName": "ym",
                "split": "even",
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            },
            {
                "deviceClasses": [
                    "iphone",
                    "android"
                ],
                "id": "mobile_instream",
                "siteId": "obz",
                "buildName": "ym",
                "split": [
                    0.75,
                    0.25
                ],
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            }
        ],
        "dt": "2021-06-18T10:47:55.000Z",
        "variationBuilds": []
    },
    {
        "url": "ym_backup_2021_06_18_00_27_25.js",
        "type": "ab",
        "lastModified": "Thu, 17 Jun 2021 22:27:25 GMT",
        "ab": {
            "id": "vast_in_gam",
            "siteId": "obz",
            "buildName": "ym",
            "split": "even",
            "variations": {
                "C": {
                    "id": "C",
                    "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                },
                "D": {
                    "id": "D",
                    "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                }
            }
        },
        "dt": "2021-06-17T22:27:25.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_06_18_00_31_41.js",
                "type": "normal",
                "lastModified": "Thu, 17 Jun 2021 22:31:41 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_hb_without_ima",
                        "hash": "928a99e1fd290275171fa6e2e29a33db4f295721"
                    }
                },
                "variation": "D",
                "dt": "2021-06-17T22:31:41.000Z"
            }
        ]
    },
    {
        "url": "ab.js",
        "type": "ab",
        "lastModified": "Thu, 17 Jun 2021 22:10:00 GMT",
        "ab": [
            {
                "deviceClasses": [
                    "desktop",
                    "ipad"
                ],
                "id": "vast_in_gam",
                "siteId": "obz",
                "buildName": "ym",
                "split": "even",
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            },
            {
                "deviceClasses": [
                    "iphone",
                    "android"
                ],
                "id": "mobile_instream",
                "siteId": "obz",
                "buildName": "ym",
                "split": [
                    0.75,
                    0.25
                ],
                "variations": {
                    "C": {
                        "id": "C",
                        "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                    },
                    "D": {
                        "id": "D",
                        "js": "https://cdn-b.notsy.io/obz/d/ym.js"
                    }
                }
            }
        ],
        "dt": "2021-06-17T22:10:00.000Z",
        "variationBuilds": []
    },
    {
        "url": "ym_backup_2021_06_17_11_52_53.js",
        "type": "ab",
        "lastModified": "Thu, 17 Jun 2021 09:52:53 GMT",
        "ab": {
            "id": "wo_ima",
            "siteId": "obz",
            "buildName": "ym",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                }
            }
        },
        "dt": "2021-06-17T09:52:53.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_06_17_11_56_04.js",
                "type": "dev",
                "lastModified": "Thu, 17 Jun 2021 09:56:05 GMT",
                "variation": "D",
                "dt": "2021-06-17T09:56:05.000Z"
            },
            {
                "url": "ym_backup_2021_06_17_11_55_51.js",
                "type": "normal",
                "lastModified": "Thu, 17 Jun 2021 09:55:51 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "19757dd48261f2e3015f2b3e6139e7b306a9be2a"
                    },
                    "settings": {
                        "commitish": "origin/obz_outstream_video",
                        "hash": "ac55fb3f1a5e209b0bd7daaf172766d11b662377"
                    }
                },
                "variation": "C",
                "dt": "2021-06-17T09:55:51.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_05_28_17_09_00.js",
        "type": "ab",
        "lastModified": "Fri, 28 May 2021 15:09:00 GMT",
        "ab": {
            "id": "instream_outstream",
            "siteId": "obz",
            "buildName": "ym",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                },
                "C": {
                    "id": "C",
                    "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                }
            }
        },
        "dt": "2021-05-28T15:09:00.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_06_16_15_57_49.js",
                "type": "normal",
                "lastModified": "Wed, 16 Jun 2021 13:57:50 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_hb_without_ima",
                        "hash": "1eb5a15fe6e98b9cb9e5dab804d553880f2a2f9c"
                    }
                },
                "variation": "B",
                "dt": "2021-06-16T13:57:50.000Z"
            },
            {
                "url": "ym_backup_2021_06_16_15_52_09.js",
                "type": "normal",
                "lastModified": "Wed, 16 Jun 2021 13:52:09 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse",
                        "hash": "04981cc86d0cb563a9a3dc94df4e1e466f9977e0"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "5b83b69f787891404b12920b4c7fcbafc96c3d4f"
                    }
                },
                "variation": "A",
                "dt": "2021-06-16T13:52:09.000Z"
            },
            {
                "url": "ym_backup_2021_06_13_14_30_49.js",
                "type": "normal",
                "lastModified": "Sun, 13 Jun 2021 12:30:49 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse_and_hb_video_tracking",
                        "hash": "d0ebc9e1ec3072d68e7465df9c0f7fac8f0a1e39"
                    },
                    "settings": {
                        "commitish": "origin/obz_hb_without_ima",
                        "hash": "c93ae23613f48d710129e080201358194f85fc99"
                    }
                },
                "variation": "B",
                "dt": "2021-06-13T12:30:49.000Z"
            },
            {
                "url": "ym_backup_2021_06_13_14_24_38.js",
                "type": "normal",
                "lastModified": "Sun, 13 Jun 2021 12:24:39 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video_with_rtbhouse",
                        "hash": "04981cc86d0cb563a9a3dc94df4e1e466f9977e0"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "6b09f9c1538703846f1a5ee69e808b362cddb6e0"
                    }
                },
                "variation": "A",
                "dt": "2021-06-13T12:24:39.000Z"
            },
            {
                "url": "ym_backup_2021_06_11_19_13_18.js",
                "type": "normal",
                "lastModified": "Fri, 11 Jun 2021 17:13:19 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "12b17471137059295f3ba232329bc0ffd1c1c691"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "b75c65d020562ff21c809e28233d567f51b0e7e5"
                    }
                },
                "variation": "A",
                "dt": "2021-06-11T17:13:19.000Z"
            },
            {
                "url": "ym_backup_2021_06_11_19_12_57.js",
                "type": "normal",
                "lastModified": "Fri, 11 Jun 2021 17:12:58 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "19757dd48261f2e3015f2b3e6139e7b306a9be2a"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video",
                        "hash": "17351e77e73c67159a4662f0dc3bab85fe8b35c2"
                    }
                },
                "variation": "B",
                "dt": "2021-06-11T17:12:58.000Z"
            },
            {
                "url": "ym_backup_2021_06_02_22_54_33.js",
                "type": "normal",
                "lastModified": "Wed, 02 Jun 2021 20:54:33 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "12b17471137059295f3ba232329bc0ffd1c1c691"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "3125e33e2fc6790093e8494df7758038206c106d"
                    }
                },
                "variation": "A",
                "dt": "2021-06-02T20:54:33.000Z"
            },
            {
                "url": "ym_backup_2021_06_02_21_55_10.js",
                "type": "normal",
                "lastModified": "Wed, 02 Jun 2021 19:55:10 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/1.0.1_with_obz_plus_sticky_fix_and_in_view_offset_in_screen_heights",
                        "hash": "d16979cdc9db513bda8c09ecc88d84e2ed42f7c3"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "ae212d2afb49b1851ef724afc740ff9b528fb9cc"
                    }
                },
                "variation": "A",
                "dt": "2021-06-02T19:55:10.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_05_25_21_49_27.js",
        "type": "ab",
        "lastModified": "Tue, 25 May 2021 19:49:27 GMT",
        "ab": {
            "id": "instream_outstream",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                },
                "C": {
                    "id": "C",
                    "js": "https://cdn-b.notsy.io/obz/c/ym.js"
                }
            }
        },
        "dt": "2021-05-25T19:49:27.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_05_28_16_22_44.js",
                "type": "normal",
                "lastModified": "Fri, 28 May 2021 14:22:44 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/1.0.1_with_obz_plus_sticky_fix_and_in_view_offset_in_screen_heights",
                        "hash": "d16979cdc9db513bda8c09ecc88d84e2ed42f7c3"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "d614afb0eb7f6f91198dddabfe0bf65f15044fb0"
                    }
                },
                "variation": "A",
                "dt": "2021-05-28T14:22:44.000Z"
            },
            {
                "url": "ym_backup_2021_05_27_14_15_03.js",
                "type": "normal",
                "lastModified": "Thu, 27 May 2021 12:15:03 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "19757dd48261f2e3015f2b3e6139e7b306a9be2a"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video",
                        "hash": "89ee67f811b6865bb6bf874cf117d7a437fec7c4"
                    }
                },
                "variation": "B",
                "dt": "2021-05-27T12:15:03.000Z"
            },
            {
                "url": "ym_backup_2021_05_26_21_04_55.js",
                "type": "normal",
                "lastModified": "Wed, 26 May 2021 19:04:56 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "19757dd48261f2e3015f2b3e6139e7b306a9be2a"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video",
                        "hash": "41ab145927e2f8833bc347cb683d099855ccf705"
                    }
                },
                "variation": "B",
                "dt": "2021-05-26T19:04:56.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_05_13_19_24_44.js",
        "type": "ab",
        "lastModified": "Thu, 13 May 2021 17:24:45 GMT",
        "ab": {
            "id": "fluid",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                }
            }
        },
        "dt": "2021-05-13T17:24:45.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_05_20_13_10_50.js",
                "type": "normal",
                "lastModified": "Thu, 20 May 2021 11:10:51 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "19757dd48261f2e3015f2b3e6139e7b306a9be2a"
                    },
                    "settings": {
                        "commitish": "origin/obz_outstream_video",
                        "hash": "f9d37eb568f5acd56ac9d5032cbd03858a2f6ee7"
                    }
                },
                "variation": "C",
                "dt": "2021-05-20T11:10:51.000Z"
            },
            {
                "url": "ym_backup_2021_05_20_13_10_19.js",
                "type": "normal",
                "lastModified": "Thu, 20 May 2021 11:10:20 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "19757dd48261f2e3015f2b3e6139e7b306a9be2a"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video",
                        "hash": "8474bd79d97d267d2781e9cdce62e3385f0a0d92"
                    }
                },
                "variation": "B",
                "dt": "2021-05-20T11:10:20.000Z"
            },
            {
                "url": "ym_backup_2021_05_19_20_51_08.js",
                "type": "normal",
                "lastModified": "Wed, 19 May 2021 18:51:09 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "65434b24882a91492573d82eab0577511be82639"
                    },
                    "settings": {
                        "commitish": "origin/obz_outstream_video",
                        "hash": "5f1933b0df281a74d72e20b704fcc34439d9cf14"
                    }
                },
                "variation": "C",
                "dt": "2021-05-19T18:51:09.000Z"
            },
            {
                "url": "ym_backup_2021_05_19_20_47_15.js",
                "type": "normal",
                "lastModified": "Wed, 19 May 2021 18:47:15 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/obz_video",
                        "hash": "65434b24882a91492573d82eab0577511be82639"
                    },
                    "settings": {
                        "commitish": "origin/obz_instream_video",
                        "hash": "df9e66212f8ce720abae4d1b5ddd7ad9558b33aa"
                    }
                },
                "variation": "B",
                "dt": "2021-05-19T18:47:15.000Z"
            },
            {
                "url": "ym_backup_2021_05_18_20_52_41.js",
                "type": "ab",
                "lastModified": "Tue, 18 May 2021 18:52:42 GMT",
                "ab": {
                    "id": "init_in_1_screen",
                    "split": "even",
                    "variations": {
                        "A": {
                            "id": "A",
                            "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                        },
                        "B": {
                            "id": "B",
                            "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                        }
                    }
                },
                "variation": "C",
                "dt": "2021-05-18T18:52:42.000Z"
            },
            {
                "url": "ym_backup_2021_05_18_20_37_19.js",
                "type": "normal",
                "lastModified": "Tue, 18 May 2021 18:37:21 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/1.0.1_with_obz_plus_sticky_fix_and_in_view_offset_in_screen_heights",
                        "hash": "f5119cbd3250b2d3e67e84cb420ba01d185c9399"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "e97a444dd0081a0fa1ad6eb6a9eddad01d5fc69b"
                    }
                },
                "variation": "A",
                "dt": "2021-05-18T18:37:21.000Z"
            },
            {
                "url": "ym_backup_2021_05_18_20_37_03.js",
                "type": "normal",
                "lastModified": "Tue, 18 May 2021 18:37:04 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/1.0.1_with_obz_plus_sticky_fix_and_in_view_offset_in_screen_heights",
                        "hash": "f5119cbd3250b2d3e67e84cb420ba01d185c9399"
                    },
                    "settings": {
                        "commitish": "origin/obz_fluid",
                        "hash": "20ef14c832b6a028816957d9116f5765fa618e66"
                    }
                },
                "variation": "B",
                "dt": "2021-05-18T18:37:04.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_05_11_13_01_17.js",
        "type": "ab",
        "lastModified": "Tue, 11 May 2021 11:01:17 GMT",
        "ab": {
            "id": "init_in_1_screen",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                }
            }
        },
        "dt": "2021-05-11T11:01:17.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_05_11_22_21_27.js",
                "type": "normal",
                "lastModified": "Tue, 11 May 2021 20:21:27 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/1.0.1_with_obz_plus_sticky_fix_and_is_fix",
                        "hash": "5c64f3322b2f7323c966ebd07dd06bbf05bb98f4"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "cf108a3873e8f7961087dbffdaa724874def19f9"
                    }
                },
                "variation": "A",
                "dt": "2021-05-11T20:21:27.000Z"
            },
            {
                "url": "ym_backup_2021_05_11_22_03_42.js",
                "type": "normal",
                "lastModified": "Tue, 11 May 2021 20:03:43 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/1.0.1_with_obz_plus_sticky_fix_and_in_view_offset_in_screen_heights",
                        "hash": "f5119cbd3250b2d3e67e84cb420ba01d185c9399"
                    },
                    "settings": {
                        "commitish": "origin/obz_ab_init_when_in_1_screen_height",
                        "hash": "03c6781fcb7910a21c03c40b9c30d0a38a8da976"
                    }
                },
                "variation": "B",
                "dt": "2021-05-11T20:03:43.000Z"
            }
        ]
    },
    {
        "url": "ym_backup_2021_04_26_16_53_05.js",
        "type": "ab",
        "lastModified": "Mon, 26 Apr 2021 14:53:05 GMT",
        "ab": {
            "id": "init_in_1_screen",
            "split": "even",
            "variations": {
                "A": {
                    "id": "A",
                    "js": "https://cdn-b.notsy.io/obz/a/ym.js"
                },
                "B": {
                    "id": "B",
                    "js": "https://cdn-b.notsy.io/obz/b/ym.js"
                }
            }
        },
        "dt": "2021-04-26T14:53:05.000Z",
        "variationBuilds": [
            {
                "url": "ym_backup_2021_05_06_00_28_54.js",
                "type": "normal",
                "lastModified": "Wed, 05 May 2021 22:28:55 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/1.0.1_with_obz_plus_sticky_fix_and_in_view_offset_in_screen_heights",
                        "hash": "21db6bf2ce51aa44501f73c9f2361d9da15f9717"
                    },
                    "settings": {
                        "commitish": "origin/obz_ab_init_when_in_1_screen_height",
                        "hash": "2c829fac2c3fe77d962f0a476bc6affb2d2c271d"
                    }
                },
                "variation": "B",
                "dt": "2021-05-05T22:28:55.000Z"
            },
            {
                "url": "ym_backup_2021_05_05_23_57_28.js",
                "type": "normal",
                "lastModified": "Wed, 05 May 2021 21:57:29 GMT",
                "version": {
                    "logic": {
                        "commitish": "origin/1.0.1_with_obz_plus_sticky_fix",
                        "hash": "1493d55e60d218d0d42a27fbf233848f8aaf523a"
                    },
                    "settings": {
                        "commitish": "origin/master",
                        "hash": "440ee61bea3aa0e37e46687bb4ef0ec58b22cbdb"
                    }
                },
                "variation": "A",
                "dt": "2021-05-05T21:57:29.000Z"
            }
        ]
    }
]

export default abData
