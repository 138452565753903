
import React from 'react';

export default class AbShortDescription extends React.Component {
    render() {
        let parallelAbs

        const ab = this.props.ab

        if (!(ab instanceof Array)) {
            parallelAbs = [ab]
        } else {
            parallelAbs = ab
        }

        const joinWithElement = <span style={{color: 'cyan'}}> || </span>

        return parallelAbs.map((abInfo, i) => {
            const splitInfo = typeof abInfo.split === "object" ? <span style={{fontWeight: 'bold', color: '#ffff99'}}>
                {'[' + abInfo.split.map(p => Math.round(p * 100)) + ']'}
            </span> : ''

            return (
                <span key={i}>
                    {abInfo.id + ' ' + Object.keys(abInfo.variations).join('/')}
                    {' '}
                    {splitInfo}
                    {i < parallelAbs.length - 1 ? joinWithElement : ''}
                </span>
            )
        })
    }
}
