
import React from 'react';

const BACKEND_DOMAIN = document.location.hostname === 'localhost' ? 'http://localhost:5000' : ''

class DeployVideoResults extends React.Component {
    render () {
        if (this.props.lastResult && this.props.lastResult.type === 'success') {
            return <div style={{fontSize: 25, backgroundColor: '#009933', color: 'Yellow', marginTop: '10px'}}>{this.props.lastResult.text}</div>
        } else if (this.props.lastResult && this.props.lastResult.type === 'error') {
            return (
                <div>
                    <div className="error-message">
                        Deploy failed. Error message:
                    </div>
                    <div className="error-message" style={{marginTop: '20px'}}>
                        {this.props.lastResult.text}
                    </div>
                </div>
            )
        } else {
            return ''
        }
    }
}

class DeployVideo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            deployInProcess: false,
            lastResult: null
        };
    }

    startDeploy() {
        this.setState({deployInProcess: true})

        fetch(
            BACKEND_DOMAIN + '/api/deployVideo',
            {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(
            (result) => {
                this.setState({deployInProcess: false})

                if (result.ok) {
                    this.setState({lastResult: {type: 'success', text: 'Deploy complete'}})
                } else {
                    result.text().then(text => {
                        this.setState({deployInProcess: false, lastResult: {type: 'error', text: text}})
                    })

                }
            },
            (error) => {
                console.log(error)

                this.setState({deployInProcess: false, lastResult: {type: 'error', text: 'Request to server has failed'}})
            }
        )
    }

    render () {
        return (
            <div>
                <div>video.js</div>
                <DeployVideoActionBlock deployComplete={!!this.state.lastResult} deployInProcess={this.state.deployInProcess} handleClick={() => this.startDeploy()} />
                <DeployVideoResults lastResult={this.state.lastResult} />
            </div>
        )
    }
}

class DeployVideoActionBlock extends React.Component {
    render () {
        if (this.props.deployComplete) {
            return ''
        }

        if (this.props.deployInProcess) {
            return (
                <p>WAITING ...</p>
            )
        } else {
            return (
                <button className="deploy-button" onClick={this.props.handleClick}>DEPLOY</button>
            )
        }
    }
}

export default DeployVideo
