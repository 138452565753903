import React from 'react';
import {Button, styled} from "@mui/material";
import {queryApiPost} from "../api";
import ErrorComponent from "./error";

const ButtonLarge = styled(Button)({
    width: '300px',
    height: '75px',

});

export class RollbackPage extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            done: false
        }
    }
    handleRolloutStart() {
        this.setState({loading: true})

        queryApiPost('/api/rollbackToVersion', {siteId: this.props.computedMatch.params.siteId, version: this.props.computedMatch.params.buildPath })
            .then(
                () => {
                    this.setState({done: true, loading: false})
                }
            ).catch((e) => this.setErrorState(e))
    }

    setErrorState(errorMessage) {
        this.setState({errorMessages: [...this.state.errorMessages || [], errorMessage]})
    }

    getErrorComponent() {
        return <ErrorComponent errorMessages={this.state.errorMessages}></ErrorComponent>
    }

    render() {
        if (this.state.errorMessages?.length) {
            return (this.getErrorComponent())
        }

        return (
            <div style={{minHeight: '75vh', maxHeight: '75vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <h3>
                    Rollback site {this.props.computedMatch.params.siteId} to version ${this.props.computedMatch.params.buildPath}
                </h3>


                {
                    this.state.loading && <div>Loading...</div>
                }

                {
                    this.state.done && <h3 style={{color: '#4BB543'}}> Done! </h3>
                }

                {
                    (!this.state.loading && !this.state.done) && <div style={{width: '100%'}}>
                        <ButtonLarge onClick={() => this.handleRolloutStart()} size="large" variant="contained">Rollback</ButtonLarge>

                    </div>
                }

            </div>
        )
    }

}