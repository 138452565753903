export class LocalStorageService {
    static getAccesses() {
        return JSON.parse(localStorage.getItem('accesses')) || {}
    }

    static setAccesses(accesses) {
        localStorage.setItem('accesses', JSON.stringify(accesses))
    }

    static getToken() {
        return localStorage.getItem('token')
    }
}