
import React from 'react';
import {Redirect} from "react-router-dom";

import BuildInfo from './BuildInfo.js'

import {queryApiGet, UnauthorizedQueryError, RequestProcessingError} from '../api.js'
import ErrorComponent from "./error";
import {LocalStorageService} from "../services/localstorage.service";

class ListSites extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            sitesInfo: null,
            errorOccurred: false,
            unauthorized: false,
            accesses: LocalStorageService.getAccesses()
        }

        this.processError = this.processError.bind(this)
    }

    componentDidMount() {
        this.loadSitesInfo()
    }

    processError(e) {
        if (e instanceof UnauthorizedQueryError) {
            console.error('Unauthorized')
        } else if (e instanceof RequestProcessingError) {
            console.error(e.message)

            this.setState({
                errorOccurred: true
            })
        } else {
            throw e
        }
    }

    loadSitesInfo() {
        queryApiGet(
            '/api/sitesStatuses'
        ).then(data => {
            this.setState({
                loadingData: false,
                sitesInfo: data
            })
        }).catch((e) => this.setErrorState(e))
    }
    setErrorState(errorMessage) {
        this.setState({errorMessages: [...this.state.errorMessages || [], errorMessage]})
    }

    getErrorComponent() {
        return <ErrorComponent errorMessages={this.state.errorMessages}></ErrorComponent>
    }

    getDeployNewSitesButton() {
        return this.state.accesses.deployNewSite ? (<div style={{marginBottom: '20px'}}>
            <a href={'/newSite'} style={{color: 'white'}}>Deploy new site</a>
        </div>) : ''
    }

    render () {
        if (this.state.unauthorized) {
            return <Redirect to={'/login'}/>
        } else if (this.state.errorMessages?.length) {
            return (this.getErrorComponent())
        } else if (this.state.loadingData) {
            return (
                <div style={{textAlign: "center"}}>
                    Loading. Please wait...
                </div>
            )
        }

        else {

            const siteInfoRows = this.state.sitesInfo.map(
                siteInfo => <BuildInfo
                    build={siteInfo}
                    key={siteInfo.siteId}
                    includeSiteId={true}
                    includeActionsBlock={true}
                    enabledActions={{update: true, startAB: true, goToHistory: true, goToDemos: true}}
                />
            )

            return (
                <div style={{width: "100%"}}>
                    <table border={1} width={'85%'} style={{fontSize: 19}} align={'center'}>
                        <thead>
                        <tr>
                            <td rowSpan={2}>ID</td>
                            {
                                typeof this.state.sitesInfo[0].domain !== "undefined" &&
                                    <td rowSpan={2}>Domain</td>
                            }
                            <td colSpan={4}>Build info</td>
                            <td rowSpan={2}>Deployed At</td>
                            <td rowSpan={2}>Controls</td>
                        </tr>
                        <tr>
                            <td>Logic</td>
                            <td>Logic Hash</td>
                            <td>Settings</td>
                            <td>Settings Hash</td>
                        </tr>
                        </thead>
                        <tbody>
                        {siteInfoRows}
                        </tbody>
                    </table>
                    {this.getDeployNewSitesButton()}
                </div>
            )
        }
    }
}

export default ListSites
